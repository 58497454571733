
<template>
    <div class="flex direction center task_page">
      sdfa
        <!-- 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 -->
    </div>
  </template>
  <script>
  
  export default {
    
    data() {
      return {
        
      }
    },
    created(){
      
    },
    mounted(){
      
    },
  
    methods:{
      
        
    }
  }
  </script>
  
  <style scoped lang="scss">
  
  </style>
  