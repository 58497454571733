<template>
  <div class="test-slide-wrapper" id="home-index">
    <SlideHorizontal name="first" v-model:index="baseIndex">
      <SlideItem class="sidebar">
      </SlideItem>
      <SlideItem>
        <SlideHorizontal
          class="first-horizontal-item"
          name="second"
          :change-active-index-use-anim="false"
          v-model:index="navIndex"
        >
        <div :active="navIndex === 0 && baseIndex === 1" ></div>
        <div :active="navIndex === 1 && baseIndex === 1" ></div>
        <div :active="navIndex === 2 && baseIndex === 1" ></div>
        <div :active="navIndex === 3 && baseIndex === 1" ></div>
          <Slide4 :active="navIndex === 4 && baseIndex === 1" />
        </SlideHorizontal>

        <BaseFooter v-bind:init-tab="1" />
      </SlideItem>
    </SlideHorizontal>
  </div>
</template>

<script>
import SlideHorizontal from '@/components/SlideHorizontal.vue'
import SlideItem from '@/components/SlideItem.vue'
import Slide4 from '@/components/Slide4.vue'
import BaseFooter from '@/components/BaseFooter.vue'

// SlideHorizontal , 
export default {
  components:{SlideHorizontal, SlideItem, Slide4, BaseFooter},
  data(){
    return {
      baseIndex: 1,
      navIndex: 4,
    }
  }
}



// import { onActivated, onDeactivated, onMounted, onUnmounted, reactive, ref } from 'vue'
// import bus, { EVENT_KEY } from '../../utils/bus'
// import { useNav } from '@/utils/hooks/useNav'
// import Slide4 from '@/pages/home/slide/Slide4.vue'
// import { DefaultUser } from '@/utils/const_var'
// import { _no } from '@/utils'
// const nav = useNav()
// const state = reactive({
//   active: true,
//   baseIndex: 1,
//   navIndex: 4,
//   itemIndex: 0,
//   test: '',
//   recommendList: [],
//   isSharing: false,
//   canMove: true,
//   shareType: -1,
//   showPlayFeedback: false,
//   showShareDuoshan: false,
//   showShareDialog: false,
//   showShare2WeChatZone: false,
//   showDouyinCode: false,
//   showFollowSetting: false,
//   showFollowSetting2: false,
//   showBlockDialog: false,
//   showChangeNote: false,
//   shareToFriend: false,

//   commentVisible: false,
//   fullScreen: false,
//   currentItem: {
//     aweme_id: '',
//     author: DefaultUser,
//     isRequest: false,
//     aweme_list: []
//   }
// })

// function delayShowDialog(cb: Function) {
//   setTimeout(cb, 400)
// }

// function setCurrentItem(item) {
//   if (!state.active) return
//   // console.log('sss',item,state.baseIndex)
//   if (state.baseIndex !== 1) return
//   if (state.currentItem.author.uid !== item.author.uid) {
//     state.currentItem = {
//       ...item,
//       isRequest: false,
//       aweme_list: []
//     }
//   }
//   // console.log('item', item)
// }

// onMounted(() => {
//   bus.on(EVENT_KEY.ENTER_FULLSCREEN, () => {
//     if (!state.active) return
//     state.fullScreen = true
//   })
//   bus.on(EVENT_KEY.EXIT_FULLSCREEN, () => {
//     if (!state.active) return
//     state.fullScreen = false
//   })
//   bus.on(EVENT_KEY.OPEN_COMMENTS, () => {
//     if (!state.active) return
//     bus.emit(EVENT_KEY.ENTER_FULLSCREEN)
//     state.commentVisible = true
//   })
//   bus.on(EVENT_KEY.CLOSE_COMMENTS, () => {
//     if (!state.active) return
//     bus.emit(EVENT_KEY.EXIT_FULLSCREEN)
//     state.commentVisible = false
//   })
//   bus.on(EVENT_KEY.SHOW_SHARE, () => {
//     if (!state.active) return
//     state.isSharing = true
//   })
//   bus.on(EVENT_KEY.NAV, ({ path, query }) => {
//     if (!state.active) return
//     nav(path, query)
//   })
//   bus.on(EVENT_KEY.GO_USERINFO, () => {
//     if (!state.active) return
//     state.baseIndex = 2
//   })
//   bus.on(EVENT_KEY.CURRENT_ITEM, setCurrentItem)
// })

// onUnmounted(() => {
//   bus.offAll()
// })

// onActivated(() => {
//   state.active = true
//   bus.emit(EVENT_KEY.TOGGLE_CURRENT_VIDEO)
// })

// onDeactivated(() => {
//   state.active = false
//   bus.emit(EVENT_KEY.TOGGLE_CURRENT_VIDEO)
// })

// function closeComments() {
//   bus.emit(EVENT_KEY.CLOSE_COMMENTS)
// }

// function dislike() {
//   // listRef.value.dislike(state.list[1])
//   // state.list[state.index] = state.list[1]
//   // _notice('操作成功，将减少此类视频的推荐')
// }
</script>

<style scoped lang="scss">
.test-slide-wrapper {
  font-size: 14rem;
  width: 100%;
  height: 100%;
  background: black;
  overflow: hidden;

  .sidebar {
    touch-action: pan-y;
    width: 80%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
    background: rgb(22, 22, 22);
    padding: 10rem;
    padding-bottom: 20rem;
    box-sizing: border-box;

    & > .header {
      font-size: 16rem;
      display: flex;
      color: white;
      justify-content: space-between;
      align-items: center;

      .right {
        border-radius: 20rem;
        padding: 8rem 15rem;
        background: rgb(36, 36, 36);
        display: flex;
        align-items: center;
        font-size: 14rem;
        gap: 10rem;

        svg {
          font-size: 18rem;
        }
      }
    }

    .card {
      margin-top: 10rem;
      border-radius: 12rem;
      padding: 15rem;
      background: rgb(29, 29, 29);

      .header {
        margin-bottom: 8rem;
        font-size: 14rem;
        display: flex;
        color: white;
        justify-content: space-between;
        align-items: center;

        .right {
          display: flex;
          align-items: center;
          font-size: 12rem;
          gap: 4rem;
          color: gray;

          svg {
            font-size: 16rem;
          }
        }
      }

      .content {
        color: white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        .item {
          min-height: 20vw;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 14rem;
          gap: 8rem;

          svg {
            font-size: 28rem;
          }

          .xcx {
            border-radius: 12rem;
            width: 50rem;
            height: 50rem;
          }
        }

        .avatar {
          height: 25vw;

          img {
            border-radius: 50%;
            width: 50rem;
          }
        }
      }
    }
  }

  .slide-content {
    width: 100%;
    height: 100%;
  }
}

.first-horizontal-item {
  //width: 90vw;
  //height: 80vh;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - var(--footer-height)) !important;
  overflow: hidden;
  border-radius: 10rem;
}

.guide {
  color: white;
  z-index: 999;
  background: var(--active-main-bg);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16rem;
  overflow: hidden;
  text-align: center;

  .danger {
    margin-top: 10rem;
    font-size: 40rem;
    color: red;
  }

  .close {
    cursor: pointer;
    font-size: 18rem;
    color: white;
    position: absolute;
    right: 15rem;
    top: 15rem;
  }

  .txt {
    text-align: left;
    padding: 0 24rem;
  }

  img {
    display: block;
    width: 350rem;
  }
}
</style>
