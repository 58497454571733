<template>
  <div class="video-wrapper" ref="videoWrapper" :class="positionName">
    <!-- :muted="state.isMuted" -->
    <!-- :src="item.video.play_addr.url_list[0]" -->
    <video :src="videoPath" :poster="poster" ref="videoEl" :muted="true" preload="true" loop x5-video-player-type="h5-page" :x5-video-player-fullscreen="false" :webkit-playsinline="true" :x5-playsinline="true" :playsinline="true"
      :fullscreen="false" :autoplay="true">
      <p>您的浏览器不支持 video 标签。</p>
    </video>
    <div class="title_content">
      <div class="user_name">@Hello</div>
      <div class="user_account">Tg:1sdaf1909fks</div>
    </div>
  </div>
</template>

<script lang="js">
import { _checkImgUrl, _duration, _stopPropagation } from '@/utils'
import bus, { EVENT_KEY } from '../utils/bus'
import { SlideItemPlayStatus } from '@/utils/const_var'
import { computed, onMounted, onUnmounted, nextTick, provide, ref, reactive } from 'vue'
import { _css } from '@/utils/dom'

export default {
  name:"BaseVideo",
  props:{
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    position: {
      type: Object,
      default: () => {
        return {}
      }
    },
    //用于第一条数据，自动播放，如果都用事件去触发播放，有延迟
    isPlay: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    isMy: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    isLive: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data(){
    return {
      videoEl:null,
      progressEl:null,

      videoPath:"/video.mp4",
      loading: false,
      paused: false,
      isMuted: window.isMuted,
      status: null,
      duration: 0,
      step: 0,
      currentTime: -1,
      playX: 0,
      start: { x: 0 },
      last: { x: 0, time: 0 },
      height: 0,
      width: 0,
      isMove: false,
      ignoreWaiting: false, //忽略waiting事件。因为改变进度会触发waiting事件，烦的一批
      test: [1, 2],
      localItem: {},
      progressBarRect: {
        height: 0,
        width: 0
      },
      videoScreenHeight: 0,
      commentVisible: false
    }
  },
  created(){
    this.status = this.isPlay ? SlideItemPlayStatus.Play : SlideItemPlayStatus.Pause
    this.localItem = this.item;
  },
  computed:{
    poster(){
      // console.log('this.item.video.poster======',this.item.video.poster)
      return _checkImgUrl(this.item.poster)
    },
    durationStyle(){
      return { width: this.playX + 'px' }
    },
    isPlaying(){
      return this.status === SlideItemPlayStatus.Play
    },
    positionName(){
      return 'item-' + Object.values(this.position).join('-')
    },
    progressClass(){
      if (this.isMove) {
        return 'move'
      } else {
        return this.isPlaying ? '' : 'stop'
      }
    },
  },
  mounted(){
    this.videoEl = this.$refs.videoEl;
    // this.progressEl = this.$refs.progressEl;
    this.height = document.body.clientHeight
    this.width = document.body.clientWidth
    this.videoEl.currentTime = 0
    let fun = (e) => {
      this.currentTime = Math.ceil(e.target.currentTime)
      this.playX = (this.currentTime - 1) * this.step
    }
    this.videoEl.addEventListener('loadedmetadata', () => {
        this.videoScreenHeight = this.videoEl.videoHeight / (this.videoEl.videoWidth / this.width)
        this.duration = this.videoEl.duration
        // this.progressBarRect = this.progressEl.getBoundingClientRect()
        this.step = this.progressBarRect.width / Math.floor(this.duration)
        this.videoEl.addEventListener('timeupdate', fun)
      })

    let eventTester = (e, t) => {
      this.videoEl.addEventListener(
        e,
        () => {
          // console.log('eventTester', e, this.item.aweme_id)
          if (e === 'playing') this.loading = false
          if (e === 'waiting') {
            if (!this.paused && !this.ignoreWaiting) {
              this.loading = true
            }
          }
          let s = false
          if (s) {
            console.log(e, t)
          }
        },
        false
      )
    }
    eventTester('waiting', '等待数据，并非错误') //等待数据，并非错误
    eventTester('playing', '开始回放') //开始回放
    bus.on(EVENT_KEY.SINGLE_CLICK_BROADCAST, this.click)
    bus.on(EVENT_KEY.DIALOG_MOVE, this.onDialogMove)
    bus.on(EVENT_KEY.DIALOG_END, this.onDialogEnd)
    bus.on(EVENT_KEY.OPEN_COMMENTS, this.onOpenComments)
    bus.on(EVENT_KEY.CLOSE_COMMENTS, this.onCloseComments)
    bus.on(EVENT_KEY.OPEN_SUB_TYPE, this.onOpenSubType)
    bus.on(EVENT_KEY.CLOSE_SUB_TYPE, this.onCloseSubType)
    bus.on(EVENT_KEY.REMOVE_MUTED, this.removeMuted)
  },
  unmounted(){
    bus.off(EVENT_KEY.SINGLE_CLICK_BROADCAST, this.click)
    bus.off(EVENT_KEY.DIALOG_MOVE, this.onDialogMove)
    bus.off(EVENT_KEY.DIALOG_END, this.onDialogEnd)
    bus.off(EVENT_KEY.OPEN_COMMENTS, this.onOpenComments)
    bus.off(EVENT_KEY.CLOSE_COMMENTS, this.onCloseComments)
    bus.off(EVENT_KEY.OPEN_SUB_TYPE, this.onOpenSubType)
    bus.off(EVENT_KEY.CLOSE_SUB_TYPE, this.onCloseSubType)
    bus.off(EVENT_KEY.REMOVE_MUTED, this.removeMuted)
  },
  methods:{
    removeMuted() {
      this.isMuted = false
    },
    onOpenSubType() {
      this.commentVisible = true
    },
    onCloseSubType() {
      this.commentVisible = false
    },
    onDialogMove({ tag, e }) {
      if (this.commentVisible && tag === 'comment') {
        _css(this.videoEl, 'transition-duration', `0ms`)
        _css(this.videoEl, 'height', `calc(var(--vh, 1vh) * 30 + ${e}px)`)
      }
    },
    onDialogEnd({ tag, isClose }) {
      if (this.commentVisible && tag === 'comment') {
        _css(videoEl, 'transition-duration', `300ms`)
        if (isClose) {
          this.commentVisible = false
          _css(videoEl, 'height', '100%')
        } else {
          _css(videoEl, 'height', 'calc(var(--vh, 1vh) * 30)')
        }
      }
    },
    onOpenComments(id) {
      if (id === this.item.aweme_id) {
        _css(this.videoEl, 'transition-duration', `300ms`)
        _css(this.videoEl, 'height', 'calc(var(--vh, 1vh) * 30)')
        this.commentVisible = true
      }
    },
    onCloseComments() {
      if (this.commentVisible) {
        _css(this.videoEl, 'transition-duration', `300ms`)
        _css(this.videoEl, 'height', '100%')
        this.commentVisible = false
      }
    },
    click({ uniqueId, index, type }) {
      if (this.position.uniqueId === uniqueId && this.position.index === index) {
        if (type === EVENT_KEY.ITEM_TOGGLE) {
          if (this.isLive) {
            this.pause()
            bus.emit(EVENT_KEY.NAV, {
              path: '/home/live',
              query: { id: this.item.aweme_id }
            })
          } else {
            if (this.status === SlideItemPlayStatus.Play) {
              this.pause()
            } else {
              this.play()
            }
          }
        }
        if (type === EVENT_KEY.ITEM_STOP) {
          this.videoEl.currentTime = 0
          this.ignoreWaiting = true
          this.pause()
          setTimeout(() => (this.ignoreWaiting = false), 300)
        }
        if (type === EVENT_KEY.ITEM_PLAY) {
          this.videoEl.currentTime = 0
          this.ignoreWaiting = true
          this.play()
          setTimeout(() => (this.ignoreWaiting = false), 300)
        }
      }
    },
    play() {
      this.status = SlideItemPlayStatus.Play
      // videoEl.volume = 1
      this.videoEl.play()
    },
    pause() {
      this.status = SlideItemPlayStatus.Pause
      this.videoEl.pause()
    },
    touchstart(e) {
      _stopPropagation(e)
      this.start.x = e.touches[0].pageX
      this.last.x = this.playX
      this.last.time = this.currentTime
    },
    touchmove(e) {
      // console.log('move',e)
      _stopPropagation(e)
      this.isMove = true
      this.pause()
      let dx = e.touches[0].pageX - this.start.x
      this.playX = this.last.x + dx
      this.currentTime = this.last.time + Math.ceil(Math.ceil(dx) / this.step)
      if (this.currentTime <= 0) this.currentTime = 0
      if (this.currentTime >= this.duration) this.currentTime = this.duration
    },
    touchend(e) {
      _stopPropagation(e)
      if (this.isPlaying) return
      setTimeout(() => (this.isMove = false), 1000)
      this.videoEl.currentTime = this.currentTime
      this.play()
    }
  }
}
</script>

<style scoped lang="scss">
.video-wrapper {
  position: relative;
  font-size: 14rem;
  width: 100%;
  height: 100%;
  text-align: center;
  .title_content{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 16px 10px;
    box-sizing: border-box;
    .user_name{
      text-align: start;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
    .user_account{
      text-align: start;
      font-size: 14px;
      font-weight: bold;
      margin-top: 6px;
      color: #fff;
    }
  }
  
  video {
    max-width: 100%;
    height: 100%;
    transition: height, margin-top 0.3s;
    //background: black;
    /*position: absolute;*/
  }

  .float {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    .normal {
      position: absolute;
      bottom: 0;
      width: 100%;
      transition: all 0.3s;

      .comment-status {
        display: flex;
        align-items: center;

        .comment {
          .type-comment {
            display: flex;
            background: rgb(130, 21, 44);
            border-radius: 50px;
            padding: 3px;
            margin-bottom: 20px;

            .avatar {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .right {
              margin: 0 10px;
              color: var(--second-text-color);

              .name {
                margin-right: 10px;
              }

              .text {
                color: white;
              }
            }
          }

          .loveds {
          }

          .type-loved {
            width: 40px;
            height: 40px;
            position: relative;
            margin-bottom: 20px;
            animation: test 1s;
            animation-delay: 0.5s;

            .avatar {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .loved {
              position: absolute;
              bottom: 0;
              left: 20px;
              width: 10px;
              height: 10px;
              background: red;
              padding: 3px;
              border-radius: 50%;
              border: 2px solid white;
            }
          }

          @keyframes test {
            from {
              display: block;
              transform: translate3d(0, 0, 0);
            }
            to {
              display: none;
              transform: translate3d(0, -60px, 0);
            }
          }
        }
      }
    }

    .progress {
      z-index: 10;
      position: absolute;
      bottom: -1rem;
      height: 10rem;
      left: calc((100% - 90%) / 2);
      width: 90%;
      display: flex;
      align-items: flex-end;
      margin-bottom: 2rem;

      .time {
        position: absolute;
        z-index: 9;
        font-size: 24px;
        bottom: 50px;
        left: 0;
        right: 0;
        color: white;
        text-align: center;

        .duration {
          color: darkgray;
        }
      }

      .bg {
        transition: height 0.3s;
        position: absolute;
        width: 100%;
        height: 2rem;
        background: #4f4f4f;
        border-radius: 10rem;
      }

      .progress-line {
        transition: height 0.3s;
        height: calc(2rem + 0.5rem);
        width: 50px;
        border-radius: 10rem 0 0 10rem;
        background: #777777;
        z-index: 1;
      }

      .point {
        transition: all 0.2s;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: gray;
        z-index: 2;
        transform: translate(-1rem, 1rem);
      }
    }

    & .move {
      .bg {
        height: 10rem;
        background: var(--active-main-bg);
      }

      .progress-line {
        height: 10rem;
        background: var(--second-text-color);
      }

      .point {
        width: 10rem;
        height: 10rem;
        background: white;
      }
    }

    & .stop {
      .bg {
        height: 4rem;
      }

      .progress-line {
        height: 4rem;
        background: white;
      }

      .point {
        width: 4rem;
        height: 4rem;
        background: white;
      }
    }
  }
}

.living {
  position: absolute;
  left: 50%;
  font-size: 18rem;
  border-radius: 50rem;
  border: 1px solid #e0e0e0;
  padding: 15rem 20rem;
  line-height: 1;
  color: white;
  top: 70%;
  transform: translate(-50%, -50%);
}
</style>
