<template>
  <div id="rootContent" @touchmove="moveEvent" :class="['root_content',pageSizeRate > 0.65 && winHeight < 600 && 'small_window',pageSizeRate > 0.65 && winHeight > 600 && 'pad_window', pageSizeRate < 0.65 && 'phone_window']">
    <div class="root_bg"><video muted src="./assets/bg-video.mp4" loop autoplay class="bg_video"></video></div>
    <div class="root_main">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
.root_content{
  height: 100vh;
  width: 100vw;
}
.root_bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  .bg_video{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.root_main {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 1px;
  left: 0;
  z-index: 1;
  overflow-y: scroll;
}
</style>
