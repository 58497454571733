
import BaseVideo from '@/components/BaseVideo.vue'
export function _stopPropagation(e) {
  e.stopImmediatePropagation()
  e.stopPropagation()
  e.preventDefault()
}

export function _stop(e) {
  e.stopPropagation()
}

export function _copy(val) {
  const textarea = document.createElement('textarea')
  document.body.appendChild(textarea)
  textarea.style.position = 'absolute'
  textarea.style.clip = 'rect(0 0 0 0)'
  textarea.value = val
  textarea.select()
  if (document.execCommand) {
    document.execCommand('copy', true)
    _notice('已复制')
  }
  document.body.removeChild(textarea)
}


export function _time(time) {
  if (String(time).length === 10) {
    time = time * 1000
  }
  const date = new Date(Number(time))
  const now = new Date()
  const d = now.valueOf() - date.valueOf()
  let str = ''
  if (d < 1000 * 60) {
    str = '刚刚'
  } else if (d < 1000 * 60 * 60) {
    str = `${(d / (1000 * 60)).toFixed()}分钟前`
  } else if (d < 1000 * 60 * 60 * 24) {
    str = `${(d / (1000 * 60 * 60)).toFixed()}小时前`
  } else if (d < 1000 * 60 * 60 * 24 * 2) {
    str = '1天前'
  } else if (d < 1000 * 60 * 60 * 24 * 3) {
    str = '2天前'
  } else if (d < 1000 * 60 * 60 * 24 * 4) {
    str = '3天前'
  } else if (date.getFullYear() === now.getFullYear()) {
    str = _dateFormat(time, 'M_D')
  } else {
    str = _dateFormat(time, 'D')
  }
  return str
}


export function _duration(v) {
  if (!v) return '00:00'
  const m = Math.floor(v / 60)
  // let s = v % 60
  const s = Math.round(v % 60)
  let str = ''
  if (m === 0) {
    str = '00'
  } else if (m > 0 && m < 10) {
    str = '0' + m
  } else {
    str = m + ''
  }
  str += ':'
  if (s === 0) {
    str += '00'
  } else if (s > 0 && s < 10) {
    str += '0' + s
  } else {
    str += s
  }
  return str
}

export function _getUserDouyinId(item) {
  return item.author.unique_id || item.author.short_id
}

/**
 * @param {number} duration
 */
export function _sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

export function cloneDeep(val) {
  return JSON.parse(JSON.stringify(val))
}

export function random(min, max) {
  const minCeiled = Math.ceil(min)
  const maxFloored = Math.floor(max)
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled) // 包含最小值和最大值
}

export function sampleSize(arr, num) {
  const list = []
  const indexs = []
  while (list.length !== num) {
    const j = random(0, arr.length - 1)
    if (!indexs.includes(j)) {
      list.push(arr[j])
      indexs.push(j)
    }
  }
  return list
}

export function _notice(val) {
  const div = document.createElement('div')
  div.classList.add('global-notice')
  div.textContent = val
  document.body.append(div)
  setTimeout(() => {
    document.body.removeChild(div)
  }, 1000)
}

export function _no() {
  _notice('未实现')
}

export function slideItemRender(props) {
  return function render(item, index, play, uniqueId) {
    // console.log('item', item)
    let node
    switch (item.type) {
      case 'img':
        node = <img src={item.src} style="height:100%;" alt={''} />
        break
      // case 'imgs':
      //   node = <SlideAlbum isPlay={play} index={index} position={{ uniqueId, index }} {...props} />
      //   break
      case 'user':
        // node = <SlideUser {...props} />
        break
      case 'send-video':
        node = <video src={item.src} style="height:100%;" />
        break
      default:
        node = (
          <BaseVideo
            isPlay={play}
            item={item}
            index={index}
            position={{ uniqueId, index }}
            {...props}
          />
        )
        break
    }
    return node
  }
}
export function _checkImgUrl(url) {
  // console.log(url)
  if (!url) return
  //本地图片
  if (
    url.includes('assets/img') ||
    url.includes('file://') ||
    url.includes('data:image') ||
    url.includes('http') ||
    url.includes('https')
  ) {
    return url
  }
  return '/images/' + url
}
