<template>
  <SlideItem>
    <SlideList
      uniqueId="home"
      style="background: #000"
      :active="props.active"
    />
  </SlideItem>
</template>

<script setup lang="jsx">
import SlideItem from './SlideItem.vue'
import SlideList from './SlideList.vue'
const props = defineProps({
  active: {
    type: Boolean,
    default: false
  }
})
</script>
